<template>
    <div>
        <div v-for="(item, index) of data.items" :key="index" class="body">
            <div class="icon" v-if="item.icon">
                <img :src="item.icon" />
            </div>
            <div class="text">
                <div class="title">
                    <a :href="item.releaseNoteViewRouter" style="color: #4577bb">{{ item.name }}</a>
                </div>
                <div class="info">
                    <div>{{ item.lable }}</div>
                    <div class="describe">
                        <div v-if="item.runtimeEnv" style="margin-right: 5px;">运行环境：{{ item.runtimeEnv }}</div>
                        <div v-if="item.updateTime">更新日期：{{ item.updateTime.substr(0, 10) }}</div>
                    </div>
                </div>
            </div>
            <div class="button">
                <el-dropdown trigger="click" split-button v-if="item.downloadFlag && item.downloadList.length"
                    style="margin-top: 2px;">
                    <a class="el-dropdown-link"
                        :href="item.downloadList[0].url ? item.downloadList[0].url : null" @click="handleDownload(item.downloadList[0])">
                        <div style="color: white;width: 120px;height: 100%;padding-top: 10%;">
                            <div style="margin-bottom: 2px;">立即下载</div>
                            {{ item.downloadList[0].systemName }}
                        </div>
                    </a>
                    <el-dropdown-menu slot="dropdown" :append-to-body="false">
                        <div style="color: darkgray;display: flex;padding: 0 20px;">
                            <div style="width: 150px;"></div>
                            <div style="width: 75px;text-align: center;">直接下载</div>
                            <div style="width: 55px;text-align: center;">百度网盘</div>
                        </div>
                        <el-dropdown-item v-for="(button, index) of item.downloadList" :key="index">
                            <div style="display: flex;">
                                <div class="system-name" :title="button.systemName">{{ button.systemName }}</div>
                                <div class="system-bit">{{ button.systemBit }}</div>
                                <a :href="button.url ?  button.url : null" style="width: 75px;text-align: center;" @click="handleDownload(button)">
                                    <i class="el-icon-download" style="color: #497ddc;font-size: 18px;font-weight: bold;"></i>
                                </a>
                                <a :href="button.baiduNetdisk" style="width: 55px;text-align: center;" @click="handleDownload(button)">
                                    <i class="el-icon-download" style="font-size: 18px;font-weight: bold;"></i>
                                </a>
                            </div>
                            <el-divider v-if="index + 1 < item.downloadList.length"></el-divider>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <a v-else :href="item.buttonViewRouter" style="color: white;">
                    <el-button style="width: 150px;">
                        {{ item.buttonName }}
                    </el-button>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      products: [],
      userInfo: null
    };
  },
  created() {
    this.userInfo = this.$utils.getStorage('userInfo');
  },
  methods: {
    async handleDownload(button) {
      const currentDate = new Date();
      const formattedTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
      let obj = {};
      if (this.userInfo) {
        obj = {
          downloadId: button.id,
          event: 'download',
          eventTime: formattedTime,
          message: {
            downloadId: button.id,
            productId: button.productId
          },
          userName: this.userInfo.sub
        };
      } else {
        obj = {
          downloadId: button.id,
          event: 'download',
          eventTime: formattedTime,
          message: {
            downloadId: button.id,
            productId: button.productId
          }

        };
      }
      console.log('obj111', obj);
      const res = await this.$api.addEventMessage(obj);
      if (res.code == 200) {
        console.log(res + '成功');
      }
    }
  }

};
</script>

<style lang="scss" scoped>
.body {
    max-width: 900px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.system-name {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    width: 100px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
}
.system-bit {
    font-size: 16px;
    width: 50px;
    text-align: center;
}

.icon img {
    height: 80px;
    width: 80px;
}

.text {
    flex: 1;
    padding-right: 50px;
    justify-content: unset;
    display: flex;
    flex-direction: column;
}

.title {
    color: rgba(32, 32, 32, 1);
    font-size: 22px;
    font-family: PingFangSC-Regular;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
    margin: 15px auto 0 50px;
}

.info {
    color: rgba(138, 138, 139, 1);
    font-size: 14px;
    font-family: PingFangSC-Regular;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
    margin: 10px auto 0 50px;

    .describe {
        display: flex;
    }
}

/deep/ .el-dropdown-menu__item i {
    margin-right: 0;
}

/deep/ .el-dropdown .el-dropdown__caret-button {
    width: 30px;
}

/deep/ .el-button--default {
    padding: 0;
    height: 60px;
    background-color: #497ddc;
    border-radius: 6px;
    font-size: 15px;
    color: white;
    font-weight: 500;

    &:hover {
        background-color: #5e8bde;
    }
}

/deep/ .el-dropdown .el-button-group {
    display: flex;
}

/deep/ .el-popper .popper__arrow{
    border: none;
}

/deep/ .el-popper .popper__arrow::after{
    border: none;
}

/deep/ .el-popper {
    margin-top: 8px;
}

/deep/ .el-divider--horizontal {
    margin: 2px 0;
}

@media screen and (max-width: 1150px) {
    .text {
        width: 20vw;
        padding-right: 0;
        overflow: hidden;
    }

    .title {
        font-size: 13px;
        margin: 0 auto 0 5px;
        line-height: 18px;
    }

    .info {
        line-height: 15px;
        font-size: 10px;
        margin: 5px auto 0 5px;

        .describe {
            display: block;
        }
    }

    .icon img {
        height: 50px;
        width: 50px;
    }

    /deep/ .el-button--default {
        height: 50px;
        font-size: 13px;
    }
}

@media screen and (max-width: 700px) {
    /deep/ .el-dropdown-menu {
        position: absolute !important;
        top: 45px !important;
        left: -171px !important;
        // right: 0 !important;
    }
}

/deep/ .el-dropdown-menu {
    transform:translateX(0);
    z-index: 1990 !important;
    width: fit-content;
}

@media screen and (max-width: 600px) {
    .icon {
        display: none;
    }
}
@media screen and (max-width: 400px) {
    .button {
        display: none;
    }
}
</style>
