<template>
    <div class="body">
        <ul>
            <li v-for="(item, index) in data.items" :key="index">
                <div class="image">
                    <img :src="item.cover" />
                </div>
                <div class="line">
                    <img src="@/assets/images/official/line.jpg" />
                </div>
                <div class="text">
                    <div class="title">
                        <template v-if="item.highlightFields.title">
                            <span v-html="item.highlightFields.title.join()"></span>
                        </template>
                        <template v-else>
                            {{ item.title }}
                        </template>
                    </div>
                    <div class="description">
                        <template v-if="item.highlightFields.description">
                            <span v-html="item.highlightFields.description.join()"></span>
                        </template>
                        <template v-else>
                            {{ item.description }}
                        </template>
                    </div>
                </div>
                <div class="clear"></div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
    },
    created() {
    },
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}

.body {
    width: 100%;
    max-width: 1200px;
}

.head {
    padding-bottom: 14px;
    border-bottom: 2px solid #3e3a39;

    .icon {
        width: 52px;
        float: left;
    }

    .icon img {
        width: 100%;
    }

    .name {
        float: left;
        font-size: 24px;
        padding-top: 12px;
        margin-left: 30px;
    }

    .contact {
        float: right;
        font-size: 24px;
        padding-top: 12px;
        margin-left: 30px;
        color: #3c7bbf;
    }

    .contact a {
        color: #3c7bbf;
    }
}

.body ul {
    margin-left: -3%;
}

.body li {
    height: 330px;
    padding-left: 1.8%;
    border-bottom: 1px solid #acacac;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 100%;
    float: left;
}

.image img {
    width: 100%;
    max-height: 90%;
    display: block;
    margin: auto;
}

.line {
    height: 200px;
    width: 4.5%;
    float: left;
    text-align: center;
}

.line img {
    height: 100%;
}

.text {
    width: 68%;
    float: right;

    .title {
        color: #585858;
        font-size: 30px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .description {
        color: #676767;
        font-size: 22px;
        line-height: 35px;
        margin: 25px 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }
}

@media screen and (max-width: 700px) {
    .body ul {
        width: 96%;
        margin: 0 auto;
    }

    .body li {
        height: auto;
        padding: 5vw 0;
        display: block;
    }

    .image {
        width: 80%;
        margin: 0 auto;
        float: none;
    }

    .image img {
        max-height: 60vw;
    }

    .text {
        float: none;
        width: 100%;

        .title {
            font-size: 5vw;
            margin-top: 2vw;
        }

        .description {
            display: block;
            margin: 2vw 0;
            font-size: 3.8vw;
            line-height: 5vw;
        }
    }

    .line {
        display: none;
    }
}
</style>
