<template>
  <div class="search-result">
    <template v-if="data.total">
      <div class="search-item" v-for="(item, name) in data.content" :key="name" v-if="Number(item.totalHits)">
        <div class="search-item-title">
          <span class="name">
            {{ covertName(name) }}（{{ Number(item.totalHits) }}个搜索结果）
          </span>
          <span class="more" v-if="item.totalHits > 3" @click="handleMore(name)">查看更多 ></span>
        </div>
        <div class="search-item-content">
          <template v-if="name == 'CASE'">
            <Case :data="item"></Case>
          </template>
          <template v-if="name == 'LIBRARY'">
            <Library :data="item"></Library>
          </template>
          <template v-if="name == 'PRODUCT'">
            <Product :data="item"></Product>
          </template>
          <template v-if="name == 'TOOLBOX'">
            <Toolbox :data="item"></Toolbox>
          </template>
          <template v-if="name == 'NEWS'">
            <News :data="item"></News>
          </template>
          <template v-if="name == 'COURSE'">
            <ul>
              <li :span="8" v-for="(subitem, index) in item.searchHits" :key="index">
                <Card :data="subitem.content" />
              </li>
            </ul>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <el-empty description="请输入关键字，搜索您想要的模型、案例、产品等信息" />
    </template>
  </div>
</template>

<script>
import Case from './block/cases.vue';
import News from './block/news.vue';
import Library from './block/library.vue';
import Product from './block/products.vue';
import Toolbox from './block/toolbox.vue';
import Card from '@/views/education/components/card.vue';
export default {
  components: {
    Case,
    Product,
    Library,
    Toolbox,
    Card,
    News
  },
  props: {
    data: {
      type: Object,
      default: () => { }
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      content: {}
    };
  },
  created() {
  },
  methods: {
    covertName(key) {
      return this.tabs.find(item => item.key == key).label;
    },
    handleMore(key) {
      this.$emit('check-more', this.tabs.find(item => item.key == key).value);
    }
  }
};
</script>

<style lang="scss" scoped>
.search-item {
  margin-bottom: 40px;

  &-title {
    .more {
      color: $--color-primary;
      font-size: 13px;
      margin-left: 20px;
      cursor: pointer;
    }
  }

  &-content {
    margin-top: 14px;
  }
}

.search-item-content ul {
  width: 100%;
  margin-left: 1%;
}

.search-item-content li {
  width: 31.3%;
  float: left;
  margin-right: 2%;
}

@media screen and (max-width: 700px) {
  .search-item-content ul {
    width: 96%;
    margin: 0 auto;
  }

  .search-item-content li {
    width: 100%;
    margin: 20px 0;
  }
}
</style>
